import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@mantine/core'
import styles from './PageHeader.module.scss'
import Button from '../common/Button'
import { X } from 'react-feather'
import { Link } from 'gatsby'

const PageHeader = ({ title, date, author, featuredImage }) => {
  const [backHover, setBackHover] = useState(false)
  return (
    <>
      <div className={styles.header}>
        <div className={styles.imageContainer}>
          <img src={featuredImage} alt={title} />
          <Link
            className={styles.backButton}
            to={`/`}
            onMouseOver={() => setBackHover(true)}
            onMouseOut={() => setBackHover(false)}
          >
            <X />
            {backHover && 'Close'}
          </Link>
        </div>
        {/* <div className={styles.headerContent}>
          <div className={styles.heading}>
            <h1>{title}</h1>
          </div>
        </div> */}
      </div>
    </>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader

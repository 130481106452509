import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@mantine/core'
import styles from './Article.module.scss'
import Button from '../common/Button'
import { ChevronLeft } from 'react-feather'
import { Link } from 'gatsby'
import Content from './Content'

const slugify = string => {
  return string
    .toString() // Cast to string
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string
    .replace(/\s/g, '-') // Replace each space with -
    .replace(
      /[^\w\-\u00b4\u00C0-\u00C3\u00c7\u00C9-\u00CA\u00CD\u00D3-\u00D5\u00DA\u00E0-\u00E3\u00E7\u00E9-\u00EA\u00ED\u00F3-\u00F5\u00FA]+/g,
      ''
    ) // Removes all chars that aren't words, -, ´ or some latin characters (À Á Â Ã Ç É Ê Í Ó Ô Õ Ú à á â ã ç é ê í ó ô õ ú)
}

const Article = ({ body, headings }) => {
  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function(e) {
        e.preventDefault()

        const targetId = this.getAttribute('href').substring(1)
        const targetElement = document.getElementById(targetId)

        if (targetElement) {
          // Calculate the offset for scrolling, taking into account any fixed headers
          const offset = 0 // Adjust this value if you have a fixed header

          targetElement.scrollIntoView({ behavior: 'smooth' })
        }
      })
    })
  }, [])

  return (
    <div className={styles.layout}>
      <div className={styles.content}>
        <article itemScope itemType="http://schema.org/BlogPosting">
          <Content source={body} />
        </article>
      </div>
    </div>
  )
}

export default Article
